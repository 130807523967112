import React from 'react';
import MainLayout from 'components/layouts/MainLayout'
import MainPageTitle from 'components/titles/MainPageTitle';
import location_map from "images/location_map.png"
import DeepLinkButton from "components/buttons/DeepLinkButton";
import "styles/pages/ContactUsStyles.scss"
import { StoreContext } from 'store';
import ContactUsSectionMobile from "components/sections/ContactUsSectionMobile"



function ContactUs() {

    const store = React.useContext(StoreContext)

    return (
        <MainLayout>
            <MainPageTitle>Contact us</MainPageTitle>
            {store.isMobile ? <ContactUsSectionMobile />
                : <div className='contact-us-page'>
                    <div className="content-container-1">
                        <span className="txt-size-m">We’re located in downtown San Francisco, CA.</span>

                        <p className="txt-size-m">
                            <div className="contact-email-section">
                                <span ><b>Forsight digital</b></span>
                                <DeepLinkButton
                                    className="contact-email-btn btn-size-l btn-wide bg-blue txt-color-white"
                                    href={"mailto: info@forsightdigital.com"}>
                                    Email us
                                </DeepLinkButton>
                            </div>
                            <br />
                            535 Mission, 14th Floor
                            <br />
                            San Francisco, CA 94105
                            <br />
                            T. 415.891.7622
                            <br />

                            E. info@forsightdigital.com

                        </p>

                        <img alt="location_map" src={location_map} />

                    </div>
                </div>
            }
        </MainLayout>
    )
}

export default ContactUs;