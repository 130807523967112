import React from 'react';
import location_map from "images/location_map.png"
import DeepLinkButton from "components/buttons/DeepLinkButton";
import "./ContactUsSectionStyles.scss"


function ContactUs() {
    return (
        <div className='contact-us-page-mobile'>
            <div className="content-container-1">
                <img alt="location_map" src={location_map} />
                <div className="section-text">
                    <span > <i>We’re located in downtown San Francisco, CA. </i></span>
                </div>

            </div>
            <div className="content-container-2">
                <div className="contact-email-section">
                    <DeepLinkButton
                        className="contact-email-btn btn-size-l btn-wide bg-blue txt-color-white"
                        href={"mailto: info@forsightdigital.com"}>
                        Email us
                    </DeepLinkButton>
                </div>
                <div className="section-text">
                <br/>
                <span ><b>Forsight digital</b></span>
                <br />
                535 Mission, 14th Floor
                <br />
                San Francisco, CA 94105
                <br />
                T. 415.891.7622
                <br />

                E. info@forsightdigital.com
                </div>

        </div>
        </div >
    )
}

export default ContactUs;